import React, { useEffect, useState } from "react";
import { campaignClient, getSessionClient } from "../../../../api";
import Modal from "react-modal";
import { Participant } from "../../../../helpers/utils";
import Loading from "../../../../Components/Loading";
import { convertcaps } from "../../../../helpers/utils";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import ReCAPTCHA from "react-google-recaptcha";
import { disableBrowserHistory } from "../../../../helpers/utils";
import VideoPlayer from "../../../../Components/VideoPlayer";
import { defaultStorageHelper } from "@spry/campaign-client";
import Footer from "../../../../Components/Footer";
import { useTranslation } from "react-i18next";

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

function ProfileView() {
    window.scrollTo(0, 0);

    const navigate = useNavigate();
    const student_sessionKey = window.location.pathname.split("/")[2];
    const captchaRef = React.createRef<ReCAPTCHA>();
    const [loading, setLoading] = useState(false);
    const [recapError, setCapError] = useState(false);
    const [contestants, setContestants] = useState<Participant[]>();
    const [currentIndex, setCurrentIndex] = useState(1);
    const [currentContestant, setCurrentContestant] = useState<Participant>();
    const [modalOpen, setModalOpen] = useState(false);
    const [recap, setRecap] = useState("");
    const [urlIndex, setUrlIndex] = useState<number>(0);
    const { t } = useTranslation();

    function onCaptchaChange(value: any) {
        // console.log("Captcha value:", value);
        setRecap(value);
        if (value !== "") {
            setCapError(false);
        }
    }

    async function fetchSubmissions() {
        if (loading) {
            return;
        }
        setLoading(true);

        try {
            const winners: Participant[] = await campaignClient.call("getSubmissionsv2", { winType: "national-finalist" });

            const findindex_currentWinner = winners.findIndex((winner) => winner.sessionKey === student_sessionKey);
            setCurrentIndex(findindex_currentWinner);
            setCurrentContestant(winners[findindex_currentWinner]);
            setContestants(winners);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    useEffect(() => {
        student_sessionKey === "undefined" && navigate("/");
        fetchSubmissions();
    }, []);

    const handlePrevious = async () => {
        if (contestants) {
            setCurrentContestant(contestants[currentIndex - 1]);
            setCurrentIndex(currentIndex - 1);
            setUrlIndex(urlIndex - 1);
        }
        navigate(`/profile/${currentContestant?.sessionKey}`);
    };
    const handleNext = async () => {
        if (contestants) {
            setCurrentContestant(contestants[currentIndex + 1]);
            setCurrentIndex(currentIndex + 1);
            setUrlIndex(urlIndex + 1);
        }
        navigate(`/profile/${currentContestant?.sessionKey}`);
    };

    const SubmitVote = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const captchaValue = captchaRef.current ? captchaRef.current.getValue() : "";
        if (recap === "") {
            setCapError(true);
            return;
        } else {
            e.preventDefault();
            setModalOpen(false);

            if (loading) {
                return;
            }
            setLoading(true);

            if (defaultStorageHelper.get("sodexolocal")) {
                setLoading(false);
                window.vex.dialog.alert({
                    unsafeMessage: t("public.errorThankFor"),
                    callback: () => {
                        setLoading(false);
                        return;
                    },
                });
            } else {
                try {
                    const { sessionKey } = await getSessionClient();
                    if (sessionKey) {
                        const res: any = await campaignClient.call("submitvote", { sessionKey: student_sessionKey, voter: sessionKey });
                        if (res.code === "EXIST") {
                            setLoading(false);

                            window.vex.dialog.alert({
                                unsafeMessage: t("public.errorThankFor"),
                                callback: () => {
                                    setLoading(false);
                                    return;
                                },
                            });
                        } else {
                            setLoading(false);

                            const randomKey = Math.random().toString(36).substr(2);
                            defaultStorageHelper.set("sodexolocal", randomKey);
                            navigate("/thankyou");
                        }
                    }
                } catch (e) {
                    setLoading(false);
                    window.vex.dialog.alert({
                        unsafeMessage: t("error.1"),
                        callback: () => {
                            setLoading(false);
                            return;
                        },
                    });
                }
            }
        }
    };
    const googlekey_dev = "6LfuA7AbAAAAALcYTirXGbiXSOI8eER7WfAJE63m";
    const googlekey_prod = "6LdlqpYeAAAAAPPqnqVtGOyfDHsTVzEGVKu0uZNy";

    return (
        <>
            <div className="wrapper">
                <header className="header">
                    <Link to="/" className="logo logo--small">
                        <img src="assets/images/temp/logo2.png" alt="" width="338" height="157" />
                    </Link>
                </header>
                {/* /.header */}
                {loading ? (
                    <div style={{ height: "100vh" }}>
                        <Loading />
                    </div>
                ) : (
                    <div className="main">
                        <section className="section">
                            <div className="shell">
                                <div className="section__inner">
                                    <div className="slider js-slider">
                                        <div className="slider__clip">
                                            <div className="slider__slides">
                                                <div className="slider__slide">
                                                    <div className="article-single">
                                                        {currentContestant?.metadata.videoUrl && <VideoPlayer url={currentContestant?.metadata?.videoUrl} />}

                                                        <div className="article__content">
                                                            <h2>
                                                                {convertcaps(currentContestant?.firstName ?? "Vika")} {currentContestant?.lastName ?? "Test"}
                                                            </h2>

                                                            <div className="article__meta">
                                                                <ul>
                                                                    <li>Grade {currentContestant?.metadata.grade ?? ""}</li>

                                                                    <li>{currentContestant?.metadata.districtName ?? ""}</li>

                                                                    <li>{currentContestant?.metadata.schoolName ?? ""}</li>

                                                                    <li>{currentContestant?.metadata?.region ?? ""}</li>

                                                                    <li>{currentContestant?.metadata?.release?.child.state ?? ""}</li>
                                                                </ul>
                                                            </div>
                                                            {/* / .article__meta */}

                                                            <ul className="recipe">
                                                                <li>
                                                                    <h6>{t("public.recipeName")}</h6>

                                                                    <h4>“{currentContestant?.metadata.recipe.name ?? "metadata.recipe.name"}”</h4>
                                                                </li>

                                                                <li>
                                                                    <h6>{t("public.ingredients")}</h6>

                                                                    <div style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
                                                                        <ul className="noDecoration">
                                                                            {currentContestant?.metadata.recipe?.ingredients.map(
                                                                                (line: string, index: number) =>
                                                                                    line.length > 1 && (
                                                                                        <li key={index}>{line.length > 0 && line}</li>
                                                                                    )
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                </li>

                                                                <li>
                                                                    <h6>{t("public.method")}</h6>

                                                                    <ol>
                                                                        {currentContestant?.metadata.recipe.preparationMethod.map(
                                                                            (sentence: string, j) =>
                                                                                sentence.length > 1 && <li key={j}>{sentence}</li>
                                                                        )}
                                                                    </ol>
                                                                </li>
                                                            </ul>
                                                            {/* /.recipe */}
                                                        </div>
                                                        {/* /.article__content */}

                                                        <div className="article__actions">
                                                            <button
                                                                className="btn js-popup flex-btn"
                                                                onClick={(e) => {
                                                                    setCapError(false);
                                                                    setRecap("");
                                                                    setModalOpen(true);
                                                                }}>
                                                                {t("public.voteFor")}
                                                            </button>

                                                            <button
                                                                className="btn btn--outline flex-btn"
                                                                onClick={() => {
                                                                    navigate("/");
                                                                }}>
                                                                {t("public.back")}
                                                            </button>
                                                        </div>
                                                        {/* /.article__actions */}
                                                    </div>
                                                    {/* /.article-full */}
                                                </div>
                                                {/* /.slider__slide */}
                                            </div>
                                            {/* /.slider__slides */}
                                        </div>
                                        {/* /.slider__clip */}
                                        {contestants && (
                                            <>
                                                <div className="slider__actions">
                                                    <button
                                                        onClick={handlePrevious}
                                                        className="slider__prev"
                                                        style={{ border: "none", background: "transparent" }}
                                                        disabled={currentIndex === 0 ? true : false}>
                                                        <img src="assets/images/svg/ico-arrow-prev.svg" alt="" width="25" height="40" />
                                                    </button>

                                                    <button
                                                        onClick={handleNext}
                                                        className="slider__next"
                                                        style={{ border: "none", background: "transparent" }}
                                                        disabled={currentIndex < contestants.length - 1 ? false : true}>
                                                        <img src="assets/images/svg/ico-arrow-next.svg" alt="" width="25" height="40" />
                                                    </button>
                                                </div>

                                                <div className="slider__paging visible-xs-block">
                                                    <ol>
                                                        <li key={currentIndex}>
                                                            <a href="#">{currentIndex + 1}</a>
                                                        </li>

                                                        <li>
                                                            <a href="#">{contestants?.length}</a>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </>
                                        )}
                                        {/* /.slider__paging */}
                                    </div>
                                    {/* /.slider js-slider */}
                                </div>
                                {/* /.section__inner */}

                                <figure className="section__figure hidden-xs">
                                    <img src="assets/images/svg/figures-left.svg" alt="" width="452" height="580" />
                                </figure>
                                {/* /.section__figure */}

                                <figure className="section__figure section__figure--alt hidden-xs">
                                    <img src="assets/images/svg/figures-right.svg" alt="" width="507" height="608" />
                                </figure>
                                {/* /.section__figure */}

                                <figure className="section__figure visible-xs-block">
                                    <img src="assets/images/svg/figures-mobile.svg" alt="" width="501" height="351" />
                                </figure>
                                {/* /.section__figure */}
                            </div>
                            {/* /.shell */}
                        </section>
                        {/* /.section */}
                    </div>
                )}
                {/* /.main */}
                <Footer />
                <div className="svg-sprite hidden">
                    <img src="./../../build/assets/images/sprite.svg" alt="sprite"></img>
                </div>{" "}
            </div>
            {/* /.wrapper */}
            {modalOpen && (
                <Modal
                    isOpen
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    className="popup"
                    portalClassName="modal"
                    style={{
                        overlay: {
                            position: "fixed",
                            zIndex: 1043,
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(0,0,0,0.5)",

                            //   -webkit-backface-visibility: hidden;
                        },
                    }}>
                    <div className="confirm-modal">
                        {/* <div className="modal-close"> <button onClick={()=>setModalOpen(false)} className="modal-close-btn"><img src="assets/images/svg/close.svg" alt="close"/></button></div> */}
                        <div className="center">
                            <h4>
                                {" "}
                                {t("public.cast")} {convertcaps(currentContestant?.firstName ?? "test")} {convertcaps(currentContestant?.lastName ?? "test")} ?
                            </h4>
                            <p>{t("public.captcha")}</p>
                            <div className="recaptcha">
                                <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_ENV !== "prod" ? googlekey_dev : googlekey_prod} onChange={onCaptchaChange} />
                            </div>
                            {/* /.recaptcha */}
                            {recapError && (
                                <p className="error-message">
                                    <span className="error-img">
                                        <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" /> {t("public.errorCaptcha")}
                                    </span>
                                </p>
                            )}
                            <div className="action-btns">
                                <button className="btn width100" onClick={(e) => SubmitVote(e)}>
                                    {" "}
                                    {t("public.vote")}
                                </button>
                                <button
                                    className="btn btn--outline width100"
                                    onClick={() => {
                                        setModalOpen(false);
                                    }}>
                                    {t("public.cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ProfileView;
