import React,{useEffect,useState,useRef} from 'react'
import { Outlet, useLocation } from "react-router-dom"
import { campaignClient, getSessionClient } from '../api'

type LocationReport = { path: string, hash?: string, search?: string }
let lastLocationReport: LocationReport = { path: "" }
export default function Pages() {

  const location = useLocation()

  // async function reportLocation(currentLocation: LocationReport) {

  //   let page = currentLocation.path === '/'? "Landed on landing page": ("Landed on" + " "+ currentLocation.path.slice(1) +" "+"page")
  //    console.log(page)
  //   await getSessionClient()
  //     .createFlowBuilder()
  //     .setTag("navigation")

  //     .addMetadata({"page":page})
  //     .commit()
  // }
  // useEffect(() => {
  //   const currentLocation: LocationReport = { path: location.pathname }
  //   if (location.hash && location.hash !== "#") { currentLocation.hash = location.hash }
  //   if (location.search && location.search !== "?") { currentLocation.search = location.search }
  //   if (lastLocationReport.path !== currentLocation.path || lastLocationReport.hash !== currentLocation.hash || lastLocationReport.search !== currentLocation.search) {
  //     reportLocation(currentLocation)
  //     lastLocationReport = currentLocation
  //   }
  // })
  
  return <Outlet />
}
