import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { campaignClient, getSessionClient } from "../../../../api";
import { STATES } from "../../../../helpers/constants";
import { v4 as uuidv4 } from "uuid";
import { americanZipCodeRegex, emailRegex, stringRegex } from "../../../../helpers/regexes";
import { disableBrowserHistory } from "../../../../helpers/utils";
import SignPad from "./signPad";
import Loading from "../../../../Components/Loading";
import "./index.css";
import Release from "../../../../Components/Release";
import { defaultStorageHelper } from "@spry/campaign-client";
import { useTranslation } from "react-i18next";
import { Event } from "../Attendance";

// Photo release page

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

type PhotoReleaseFormData = {
  term1: boolean;
  term2: boolean;
  shirtSize: string;
  parent: {
    firstName: string;
    lastName: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    date: string;
    sign: string;
  };
  child: {
    firstName: string;
    lastName: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
};

type State = {
  name: string;
  abbreviation: String;
};

const PhotoRelease = () => {
  disableBrowserHistory();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { session_key } = useParams();
  const [signature, setSignature] = useState("");
  const [childState, setChildState] = useState("");
  const [parentState, setParentState] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [userData, setUserData] = useState<{ term1: boolean; term2: boolean; shirtSize: string }>();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<PhotoReleaseFormData>();

  const [finalist, setFinalist] = useState({ lastName: "", firstName: "", eventDetail: {} });
  const [isFinalist, setIsFinalist] = useState(true);

  useEffect(() => {
    const userDataStor = defaultStorageHelper.get("userData");
    userDataStor && setUserData(JSON.parse(userDataStor));
  }, []);

  useEffect(() => {
    signature
      ? clearErrors("parent.sign")
      : setError("parent.sign", { type: "handle", message: "Please add Signature." });
  }, [signature]);

  useEffect(() => {
    const verifyFinalist = async () => {
      try {
        const {
          firstName,
          lastName,
          eventDetail,
        }: { firstName: string; lastName: string; email: string; eventDetail: Event } =
          await campaignClient.call("checkSubmission", {
            sessionKey: session_key,
          });
        if (firstName && eventDetail) {
          setFinalist({ firstName, eventDetail, lastName });
        } else {
          navigate("/");
        }

        setLoaded(true);
      } catch (error) {
        setIsFinalist(false);
        throw error;
      }
    };
    if (session_key) {
      verifyFinalist();
    }
  }, []);

  const handlePhotoRelease = (data: PhotoReleaseFormData) => {
    data = {
      ...data,
      term1: userData?.term1!,
      term2: userData?.term2!,
      shirtSize: userData?.shirtSize!,
    };

    const submitReleaseForm = async () => {
      try {
        data.parent.sign = signature;
        const res = await campaignClient.call("releaseform", {
          sessionKey: session_key,
          release: data,
        });
        if (isFinalist && res) {
          navigate(`/confirm/${session_key}`, { state: finalist.eventDetail });
        }
      } catch (error) {
        setIsFinalist(false);
        window.vex.dialog.alert({
          unsafeMessage: t("error.1"),
          callback: () => {
            navigate(`/attendance/${session_key}`);
          },
        });
        throw error;
      }
    };
    signature
      ? submitReleaseForm()
      : setError("parent.sign", { type: "handle", message: "Please add Signature." });
  };

  const handleBack = () => {
    navigate(`/attendance/${session_key}`);
  };

  if (!loaded) {
    return <Loading />;
  } else if (isFinalist && finalist && loaded) {
    return (
      <div className="main">
        <div className="hero hero--alt">
          <div className="shell">
            <div className="hero__image">
              <img src="/assets/images/hero-image@2x.png" alt="" width={414} />
            </div>
          </div>
        </div>
        <section className="section-main section-main--signiture">
          <div className="shell">
            <div className="section__content">
              <div className="form form--signiture">
                <form onSubmit={handleSubmit(handlePhotoRelease)}>
                  <div className="form__head">
                    <h2>{t("release.1")}</h2>
                  </div>
                  <div className="form__body">
                    <div className="form__section">
                      <Release />
                      <div className="form__section-head">
                        <h2>{t("release.8")}</h2>
                        <span className="form__notice">{t("release.9")}</span>
                      </div>
                      <div className="form__row">
                        <div className="form__cols">
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="first_name">
                              * {t("release.10")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="first_name"
                                placeholder={t("release.10")}
                                readOnly
                                value={finalist.firstName}
                                {...register("child.firstName", {
                                  required: {
                                    value: true,
                                    message: t("release.11"),
                                  },
                                  pattern: {
                                    value: stringRegex,
                                    message: t("release.12"),
                                  },
                                })}
                              />
                            </div>
                            {errors.child?.firstName && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.child.firstName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="last_name">
                              * {t("release.13")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="last_name"
                                placeholder={t("release.13")}
                                readOnly
                                value={finalist.lastName}
                                {...register("child.lastName", {
                                  required: {
                                    value: true,
                                    message: t("release.14"),
                                  },
                                  pattern: {
                                    value: stringRegex,
                                    message: t("release.15"),
                                  },
                                })}
                              />
                            </div>
                            {errors.child?.lastName && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.child.lastName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="email">
                              {t("release.16")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="email"
                                className="field"
                                id="email"
                                placeholder={t("release.16")}
                                defaultValue=""
                                {...register("child.email", {
                                  pattern: {
                                    value: emailRegex,
                                    message: t("release.41"),
                                  },
                                })}
                              />
                            </div>
                            {errors.child?.email && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.child.email.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2" />
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="address_1">
                              * {t("release.17")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="address_1"
                                placeholder={t("release.17")}
                                {...register("child.address1", {
                                  required: {
                                    value: true,
                                    message: t("release.18"),
                                  },
                                  minLength: {
                                    value: 6,
                                    message: t("release.19"),
                                  },
                                })}
                              />
                            </div>
                            {errors.child?.address1 && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.child.address1.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="address_2">
                              {t("release.20")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="address_2"
                                placeholder={t("release.20")}
                                {...register("child.address2")}
                              />
                            </div>
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="city">
                              * {t("release.21")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="city"
                                placeholder={t("release.21")}
                                {...register("child.city", {
                                  required: {
                                    value: true,
                                    message: t("release.22"),
                                  },
                                  pattern: {
                                    value: stringRegex,
                                    message: t("release.23"),
                                  },
                                })}
                              />
                            </div>
                            {errors.child?.city && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.child.city.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of4">
                            <label className="form__label" htmlFor="select_state">
                              * {t("release.24")}
                            </label>
                            <div className="form__controls">
                              <div className="select js-select">
                                <select
                                  id="select_state"
                                  data-width="100%"
                                  data-placeholder={t("release.25")}
                                  {...register("child.state", {
                                    required: {
                                      value: true,
                                      message: t("release.26"),
                                    },
                                  })}
                                  onChange={(e) => {
                                    setChildState(e.target.value);
                                  }}
                                  value={childState}>
                                  <option value=""> Select your state</option>
                                  {STATES.map((state) => {
                                    return (
                                      <option value={state.name} key={uuidv4()}>
                                        {state.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            {!childState && errors.child?.state && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.child.state.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of4">
                            <label className="form__label" htmlFor="zip">
                              * {t("release.28")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="zip"
                                placeholder={t("release.28")}
                                {...register("child.zip", {
                                  required: {
                                    value: true,
                                    message: t("release.29"),
                                  },
                                  pattern: {
                                    value: americanZipCodeRegex,
                                    message: t("release.30"),
                                  },
                                })}
                              />
                            </div>
                            {errors.child?.zip && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.child.zip.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form__section form__section-alt">
                      <div className="form__section-head">
                        <h2>{t("release.31")}</h2>
                        <div className="form__entry">
                          <p>{t("release.32")}</p>
                        </div>
                      </div>
                      <div className="form__row">
                        <div className="form__cols">
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="first_name_2">
                              * {t("release.33")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="first_name_2"
                                placeholder={t("release.33")}
                                {...register("parent.firstName", {
                                  required: {
                                    value: true,
                                    message: t("release.34"),
                                  },
                                  pattern: {
                                    value: stringRegex,
                                    message: t("release.35"),
                                  },
                                })}
                              />
                            </div>
                            {errors.parent?.firstName && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.parent.firstName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="last_name_2">
                              * {t("release.36")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="last_name_2"
                                placeholder={t("release.36")}
                                {...register("parent.lastName", {
                                  required: {
                                    value: true,
                                    message: t("release.37"),
                                  },
                                  pattern: {
                                    value: stringRegex,
                                    message: t("release.38"),
                                  },
                                })}
                              />
                            </div>
                            {errors.parent?.lastName && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.parent.lastName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="email_2">
                              * {t("release.39")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="email"
                                className="field"
                                id="emai_2"
                                placeholder={t("release.39")}
                                {...register("parent.email", {
                                  required: {
                                    value: true,
                                    message: t("release.40"),
                                  },
                                  pattern: {
                                    value: emailRegex,
                                    message: t("release.41"),
                                  },
                                })}
                              />
                            </div>
                            {errors.parent?.email && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.parent.email.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2" />
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="address_1_alt">
                              * {t("release.42")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="address_1_alt"
                                placeholder={t("release.42")}
                                {...register("parent.address1", {
                                  required: {
                                    value: true,
                                    message: t("release.44"),
                                  },
                                })}
                              />
                            </div>
                            {errors.parent?.address1 && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.parent.address1.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="address_2_alt">
                              {t("release.45")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="address_2_alt"
                                placeholder={t("release.45")}
                                {...register("parent.address2")}
                              />
                            </div>
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="city_2">
                              * {t("release.47")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="city_2"
                                placeholder={t("release.46")}
                                {...register("parent.city", {
                                  required: {
                                    value: true,
                                    message: t("release.47"),
                                  },
                                  pattern: {
                                    value: stringRegex,
                                    message: t("release.48"),
                                  },
                                })}
                              />
                            </div>
                            {errors.parent?.city && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.parent.city.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of4">
                            <label className="form__label" htmlFor="select_state_2">
                              * {t("release.49")}
                            </label>
                            <div className="form__controls">
                              <div className="select js-select">
                                <select
                                  id="select_state_2"
                                  data-width="100%"
                                  data-placeholder="Select your state"
                                  {...register("parent.state", {
                                    required: {
                                      value: true,
                                      message: t("release.50"),
                                    },
                                  })}
                                  onChange={(e) => setParentState(e.target.value)}
                                  value={parentState}>
                                  <option value="">{t("release.499")}</option>
                                  {STATES.map((state) => {
                                    return (
                                      <option key={uuidv4()} value={state.name}>
                                        {state.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              {!parentState && errors.parent?.state && (
                                <div className="error-notification">
                                  <span className="error-img">
                                    <img
                                      src="/images/exclamation-mark.svg"
                                      title="Exclamation Mark"
                                    />{" "}
                                    {errors.parent.state.message}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form__col form__col--1of4">
                            <label className="form__label" htmlFor="zip_2">
                              {t("release.51")}
                            </label>
                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                id="zip_2"
                                placeholder={t("release.52")}
                                {...register("parent.zip", {
                                  required: {
                                    value: true,
                                    message: t("release.53"),
                                  },
                                  pattern: {
                                    value: americanZipCodeRegex,
                                    message: t("release.54"),
                                  },
                                })}
                              />
                            </div>
                            {errors.parent?.zip && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.parent.zip.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="date">
                              * {t("release.56")}
                            </label>
                            <div className="form__controls js-date date">
                              <input
                                type="date"
                                className="field"
                                id="date"
                                placeholder="Date"
                                {...register("parent.date", {
                                  required: {
                                    value: true,
                                    message: t("release.57"),
                                  },
                                })}
                              />
                            </div>
                            {errors.parent?.date && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.parent.date.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form__actions">
                    <div className="button-final">
                      <SignPad onChange={(adultSign: any) => setSignature(adultSign)} />
                      {errors.parent?.sign && (
                        <div className="error-notification">
                          <p className="error-img">
                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />{" "}
                            {errors.parent?.sign.message}
                          </p>
                          <br />
                        </div>
                      )}
                      <button
                        type="button"
                        className=" btn  btn--cyan-outline btn--xxl"
                        style={{ width: "100%" }}
                        onClick={() => handleBack()}>
                        {t("release.62")}
                      </button>

                      <button type="submit" className="form__btn btn btn--cyan btn--xxl">
                        {t("release.63")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default PhotoRelease;
