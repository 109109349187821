import { Route } from "react-router-dom";
import "../i18n";
import Closed from "../Pages/Closed";
import ComingSoon from "../Pages/ComingSoon";
import Open from "../Pages/Open";
import Landing from "../Pages/Open/Landing";
import Registration from "../Pages/Open/Registration";
import Thankyou from "../Pages/Open/Thankyou";
import Attendance from "../Pages/Open/District/Attendance";
import PhotoRelease from "../Pages/Open/District/PhotoRelease";
import Confirm from "../Pages/Open/District/Confirm";
import { AlreadySigned, NotFinalist } from "../Pages/Open/Error";
import PublicLanding from "../Pages/Open/PublicPhase/Landing";
import ProfileView from "../Pages/Open/PublicPhase/ProfileView";
import PublicThankyou from "../Pages/Open/PublicPhase/Thankyou";
import LandingClosed from "../Pages/Open/LandingClosed";

export default (
    <>
        <Route path="/" element={<Open />}>
            {/* <Route path="/" element={<Landing />} /> */}
            {/* <Route path="/registration" element={<Registration />} />
      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="/notfinalist" element={<NotFinalist />} />
      <Route path="/alreadysigned" element={<AlreadySigned />} /> */}
            {/* Parents register */}
            <Route path="/confirm/:session_key" element={<Confirm />} />
            <Route path="/attendance/:session_key" element={<Attendance />} />
            <Route path="/releaseform/:session_key" element={<PhotoRelease />} />
            {/* <Route path="/" element={<LandingClosed />} /> */}

            <Route path="/" element={<PublicLanding />} />
            <Route path="/profile/:key" element={<ProfileView />} />
            <Route path="/thankyou" element={<PublicThankyou />} />
            <Route path="/closed" element={<LandingClosed />} />
            <Route path="*" element={<LandingClosed />} />
        </Route>
        <Route path="closed" element={<Closed />} />

        <Route path="coming" element={<ComingSoon />} />
    </>
);
