function Release() {
  return (
    <>
      {" "}
      <p>I hereby agree as follows:</p>
      <ol>
        <li>
          I, on behalf of myself, my beneficiaries, assigns and estate, hereby give and grant
          permission from this date (indicated below) to Sodexo, Inc., its parent, subsidiaries,
          affiliates, successors, contractors, agents, beneficiaries and assigns (herein
          collectively called “the Licensed Parties”), the world-wide right to use, copy, modify,
          edit, publish, transmit, display and copyright for any lawful purpose relating to the
          Licensed Parties and in any and all media, my name, voice, photograph, and / or video
          likeness (“My Likeness) in connection with any advertising, promotion and/or any other
          publicity (collectively, the “Advertising”).
        </li>
        <li>
          I agree that any photograph and/or video taken of me individually and/or as part of a
          group by the Licensed Parties is owned by the Licensed Parties. If I should receive any
          print, negative, or other copy thereof, I shall not authorize its use by anyone else. I
          acknowledge that My Likeness may be reproduced in photographic or other similar form in
          the advertising.
        </li>
        <li>
          I agree that no advertisement or other material need be submitted to me for any future
          approval and I hereby waive any rights of action I may have and release the Licensed
          Parties from any and all claims I may have arising from use and publication, including any
          rights to sue for defamation or violation of rights of privacy or rights of publicity.
        </li>
        <li>
          I warrant and represent that this license does not in any way conflict with any existing
          commitment on my part.
        </li>
        <li>
          Nothing herein will constitute any obligation on the Licensed Parties to make any use of
          the rights set forth herein. This Release shall be construed and the legal relations
          between the parties hereto shall be determined under the laws of the State of Maryland,
          exclusive of its choice of law provisions. If any of the provisions of this release shall
          be held to be illegal or unenforceable, the legality and enforceability of the remaining
          provisions shall not be impaired. In signing this release I affirm that I have read it in
          its entirety and that I understand and agree with its contents.
        </li>
      </ol>
    </>
  );
}

export default Release;
