import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { disableBrowserHistory } from "../../../../helpers/utils";
import { useEffect, useState } from "react";
import { campaignClient } from "../../../../api";
import Modal from "react-modal";
import Loading from "../../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// Attendance page
export type CheckSubmReturns = {
  firstName: string;
  release: string;
  status: string;
  eventDetail: Event | null;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

type AttendanceFormData = {
  term1: boolean;
  term2: boolean;
  shirtSize: string;
  eventDetail: Event | null;
};
export type Event = {
  rawEventDate?: string;
  address2?: string;
  rawEventTime?: string;
  eventDate: string;
  eventTime: string;
  address?: string;
  address1?: string;
  city: string;
  zip: string;
  state: string;
};
const Attendance = () => {
  disableBrowserHistory();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { session_key } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const [finalistFirstName, setFinalistFirstName] = useState<string>();

  const [isFinalist, setIsFinalist] = useState(true);

  const [loaded, setLoaded] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AttendanceFormData>();

  const formwatch = watch();

  useEffect(() => {
    checkSubmissions();
    const userData = defaultStorageHelper.get("userData");
    if (userData) {
      const {
        term1,
        term2,
        shirtSize,
        eventDetail,
      }: { term1: boolean; term2: boolean; shirtSize: string; eventDetail: Event | null } =
        JSON.parse(userData?.toString());
      setValue("shirtSize", shirtSize!);
      setValue("term1", term1!);
      setValue("term2", term2!);
    }
  }, []);

  const checkSubmissions = () => {
    const verifyFinalist = async () => {
      try {
        const { firstName, release, status, eventDetail } =
          await campaignClient.call<CheckSubmReturns>("checkSubmission", {
            sessionKey: session_key,
          });

        if (!release && +status >= 2) {
          setFinalistFirstName(firstName);
          setValue("eventDetail", eventDetail);
        } else if (!!release && +status >= 2) {
          setIsFinalist(false);
          window.vex.dialog.alert({
            unsafeMessage: t("error.3"),
            callback: () => {
              // navigate(`/alreadysigned`);
              navigate("/");
            },
          });
        } else {
          setIsFinalist(false);
          window.vex.dialog.alert({
            unsafeMessage: t("error.4"),
            callback: () => {
              // navigate(`/notfinalist`);
              navigate("/");
            },
          });
        }
        setLoaded(true);
      } catch (error) {
        setIsFinalist(false);
        throw error;
      }
    };
    if (session_key) {
      verifyFinalist();
    }
  };

  const handleAttendance = (data: AttendanceFormData) => {
    if (isFinalist) {
      defaultStorageHelper.set("userData", JSON.stringify(data));
      navigate(`/releaseform/${session_key}`);
    } else {
      window.vex.dialog.alert({
        unsafeMessage: t("error.2"),
        callback: () => {
          // navigate(`/attendance/${session_key}`);
          navigate("/");
        },
      });
    }
  };

  if (!loaded) {
    return <Loading />;
  } else if (isFinalist && finalistFirstName && loaded) {
    return (
      <div className="main">
        <div className="hero hero--alt">
          <div className="shell">
            <div className="hero__image">
              <img src="/assets/images/hero-image@2x.png" alt="" width={414} />
            </div>
          </div>
        </div>
        <section className="section-main">
          <div className="shell">
            <div className="section__content">
              <form onSubmit={handleSubmit(handleAttendance)}>
                <div className="section__head">
                  <h2>
                    {t("attend.1")} {finalistFirstName}!
                  </h2>
                  <div className="section__entry">
                    <p>
                      {t("attend.112")}{" "}
                      {finalistFirstName.replace(
                        /(^\w|\s\w)(\S*)/g,
                        (_: any, m1: any, m2: any) => m1.toUpperCase() + m2.toLowerCase()
                      )}{" "}
                      {t("attend.2")}
                    </p>
                    <p>{t("attend.3")}</p>
                  </div>
                </div>
                <div className="section__body">
                  <div className="form form--alt">
                    <div className="form__head">
                      <h2>{t("attend.4")}</h2>
                      <span className="form__notice">{t("attend.5")}</span>
                    </div>
                    <div className="form__body">
                      <div className="form__row">
                        <div className="checkboxes">
                          <div className="form__controls checkboxes__item">
                            <div className="checkbox checkbox--big">
                              <input
                                type="checkbox"
                                id="checkbox_yes"
                                {...register("term1", {
                                  required: {
                                    value: true,
                                    message: t("attend.9"),
                                  },
                                })}
                                //   defaultChecked={!!userData?.term1}
                              />
                              <label htmlFor="checkbox_yes">
                                {t("attend.6")}
                                {formwatch.eventDetail
                                  ? dayjs(formwatch.eventDetail.eventDate).format("MMM DD, YYYY")
                                  : ""}{" "}
                                event
                              </label>
                            </div>
                            {errors.term1 && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.term1.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form__controls checkboxes__item">
                            <div className="checkbox checkbox--big">
                              <input
                                type="checkbox"
                                id="checkbox_yes_2"
                                {...register("term2", {
                                  required: {
                                    value: true,
                                    message: t("attend.7"),
                                  },
                                })}
                                //   defaultChecked={userData?.term2 ? true : false}
                              />
                              <label htmlFor="checkbox_yes_2">{t("attend.8")}</label>
                            </div>
                            {errors.term2 && (
                              <div className="error-notification">
                                <span className="error-img">
                                  <img
                                    src="/images/exclamation-mark.svg"
                                    title="Exclamation Mark"
                                  />{" "}
                                  {errors.term2.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form__row">
                        <label className="form__label" htmlFor="select_size">
                          {t("attend.10")}
                        </label>
                        <div className="form__controls">
                          <div className="select select--size js-select">
                            <select
                              id="select_size"
                              data-width="100%"
                              data-placeholder={t("attend.11")}
                              {...register("shirtSize", {
                                required: {
                                  value: true,
                                  message: t("attend.11"),
                                },
                              })}
                              //</div>defaultValue={userData?.shirtSize ? userData.shirtSize : ""}
                            >
                              <option value="">{t("attend.14")}</option>
                              <option value="XS">XS</option>
                              <option value="S">S</option>
                              <option value="M">M</option>
                            </select>
                          </div>
                          {errors.shirtSize && (
                            <div className="error-notification">
                              <span className="error-img">
                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />{" "}
                                {errors.shirtSize.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <a onClick={() => setIsOpen(true)} className="form__link">
                          {t("attend.12")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section__actions">
                  <button
                    type="submit"
                    className="form__btn btn btn--cyan btn--xxl"
                    style={{ border: "none" }}>
                    {t("attend.13")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        <Modal
          isOpen={isOpen}
          contentLabel="My dialog"
          ariaHideApp={false}
          className="popup"
          portalClassName="modal"
          style={{
            overlay: {
              position: "fixed",
              zIndex: 1043,
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            },
          }}>
          <div className="center" style={{ position: "relative" }}>
            <div className="popup__header">
              <a
                className="close"
                onClick={() => {
                  setIsOpen(false);
                }}>
                <img width="15" src="/assets/images/svg/close.svg" alt="close" />
              </a>
            </div>
            <img src="/assets/images/Kids Chef Coat - size chart.png" alt="size_chart" />
          </div>
        </Modal>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Attendance;
