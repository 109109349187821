import { defaultStorageHelper } from "@spry/campaign-client";
import { useTranslation } from "react-i18next";
import "./index.css";

function Footer({ footerBottom = "footerBottom", className = "" }) {
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === "en";

    const languageToggle = () => {
        const newLn = isEnglish ? "sp" : "en";
        i18n.changeLanguage(newLn);
        defaultStorageHelper.set("lang", newLn);
    };

    return (
        <footer className={`footer ${footerBottom} ${className}`}>
            <div className="shell">
                <ul className="terms-nav">
                    <li>
                        <a href="https://us.sodexo.com/legal-privacy.html" target="_blank">
                            {t("footer.1")}
                        </a>
                    </li>

                    <li>
                        <a href="https://us.sodexo.com/legal-privacy/privacy-policy.html" target="_blank">
                            {t("footer.2")}
                        </a>
                    </li>
                    <li>
                        <button className="btnIcon" onClick={languageToggle}>
                            {isEnglish ? t("footer.3") : t("footer.5")}
                        </button>
                    </li>
                </ul>
                <div className="copyright">
                    <p>{`© ${new Date().getFullYear()} ${t("footer.4")}`}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
