import React, { useEffect, useState } from "react";
import { campaignClient } from "../../../../api";
import { disableBrowserHistory } from "../../../../helpers/utils";
import "./index.css";
// import { ReturnTypes } from "@sprycore/spry-api-client"

// import * as MainDbReturnTypes from "@sprycore/spry-api-client/dist/ReturnTypes";
import { Participant } from "../../../../helpers/utils";
import Loading from "../../../../Components/Loading";
import { convertcaps } from "../../../../helpers/utils";
import { Link, useNavigate } from "react-router-dom";
import VideoPlayer from "../../../../Components/VideoPlayer";
import { useTranslation } from "react-i18next";
import Footer from "../../../../Components/Footer";
import { useQuery } from "react-query";

export default function PublicLanding() {
    const navigate = useNavigate();
    disableBrowserHistory();
    const { t, i18n } = useTranslation();

    const { isLoading: loadingphase5, data: phase5 } = useQuery("getschedulephase1b", async () => {
        const res: any = await campaignClient.call("getschedule", {
            phase: "phase5",
        });
        if (res.state === "closed") {
            navigate("/closed");
        }
        return res;
    });

    const { isLoading: loadingStudents, data: contestants } = useQuery(
        ["getSubmissionsv2", phase5],
        async () => {
            const winners: Participant[] = await campaignClient.call("getSubmissionsv2", { winType: "national-finalist" });
            return winners;
        },
        { enabled: phase5?.state == "open" }
    );

    return (
        <>
            <aside className="wrapper__aside">
                {loadingphase5 && <Loading />}
                <div className="hero">
                    <div className="shell">
                        <a href="#" className="logo hero__logo">
                            <img src="assets/images/temp/logo1.png" alt="" width="536" height="250" />
                        </a>

                        <div className="hero__entry">
                            <h1>{t("public.title")}</h1>

                            <p>{t("public.body1")}</p>

                            <p>{t("public.body2")}</p>
                        </div>
                        {/* /.hero__entry */}

                        <figure className="hero__shape visible-xs-block">
                            <img src="assets/images/svg/hero-shape.svg" alt="" width="92" height="1133" />
                        </figure>
                        {/* /.hero__shape */}
                    </div>
                    {/* /.shell */}
                </div>
                {/* /.hero */}
                <Footer footerBottom="" className="footer--dark hidden-xs" />

                <figure className="wrapper__shape hidden-xs">
                    <img src="assets/images/svg/shape.svg" alt="" width="92" height="1133" />
                </figure>
                {/* /.hero__shape */}
            </aside>
            {/* /.aisde-fixed */}

            <div className="wrapper__content">
                <div className="shell">
                    <div className="articles">
                        {loadingphase5 || (loadingStudents && <Loading />)}
                        <ul>
                            {contestants &&
                                (contestants.length > 0 ? (
                                    contestants.map((contestant) => {
                                        return (
                                            <li key={contestant.sessionKey}>
                                                <article className="article">
                                                    {contestant.metadata?.videoUrl && (
                                                        <Link to={`/profile/${contestant.sessionKey}`}>
                                                            <VideoPlayer url={contestant.metadata.videoUrl} />
                                                        </Link>
                                                    )}

                                                    <Link to={`/profile/${contestant.sessionKey}`}>
                                                        <h6 style={{ paddingTop: "1rem" }}>{convertcaps(contestant.firstName + " " + contestant.lastName)}</h6>
                                                    </Link>
                                                    <Link to={`/profile/${contestant.sessionKey}`}>
                                                        <p>{convertcaps(contestant.metadata?.districtName)} </p>
                                                    </Link>
                                                    <Link to={`/profile/${contestant.sessionKey}`}>
                                                        <p>{convertcaps(contestant.metadata?.recipe?.name)}</p>
                                                    </Link>
                                                </article>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <li>
                                        <h6>There are no winners to display!</h6>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    {/* /.articles */}
                </div>
                {/* /.shell */}
            </div>
            <Footer footerBottom="" className="visible-xs-block" />

            <div className="svg-sprite hidden">
                <Footer footerBottom="" className="footer--dark hidden-xs" />
            </div>
        </>
    );
}
