import React from 'react'
import ReactDOM from 'react-dom/client'
import { handleSessionKey } from "./helpers/querystring"
import handleWwwRedirect from './helpers/wwwRedirect/handleWwwRedirect'
import CustomRoutes from "./Config/Routes"
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import App from './App'
import Pages from './Pages'
import { QueryClient, QueryClientProvider } from 'react-query'

handleSessionKey()
handleWwwRedirect()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient();


root.render(
  <React.StrictMode>
          <QueryClientProvider client={queryClient}>

    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Pages />}>
                    {CustomRoutes}
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
)