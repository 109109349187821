import React, { useState } from "react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import { useTranslation } from "react-i18next";

const SignPad = (props: any) => {
  const [imageURL, setImageURL] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const signCanvas = React.useRef() as React.MutableRefObject<any>;
  const clear = () => signCanvas.current.clear();

  const { t } = useTranslation();
  return (
    <>
      <Popup
        open={isOpen}
        contentStyle={{
          position: "fixed",
          zIndex: 1043,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        modal
        closeOnDocumentClick={false}>
        {/* {/* {(close: any, e: any) => ( */}
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              flexDirection: "column",
              height: "350px",
              width: "330px",
              borderRadius: "5%",
              position: "relative",
            }}>
            <div className="popup__header">
              <a
                className="close"
                onClick={() => {
                  setIsOpen(false);
                }}>
                <img width="15" src="/assets/images/svg/close.svg" alt="close" />
              </a>
            </div>
            <h4 style={{ color: " rgb(45 44 109)" }}>
              <b>{t("release.59")}</b>
            </h4>

            <SignaturePad
              ref={signCanvas}
              canvasProps={{
                className: "signPad",
                style: {
                  position: "relative",
                  display: "block",
                  zIndex: "9999",
                  // width: "400px",
                  height: "150px",
                  background: "white",
                  border: "2px solid grey",
                  textDecoration: "none",
                },
              }}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                paddingTop: "15px",
              }}>
              <button onClick={clear} className=" btn btn--purple-outline   ">
                {t("release.60")}
              </button>

              <button
                className="form__btn btn btn--blue btn"
                onClick={(e) => {
                  e.preventDefault();
                  setImageURL(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                  setIsOpen(false);
                  props.onChange(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                }}>
                {t("release.61")}
              </button>
            </div>
          </div>
        </>
      </Popup>
      <button
        onClick={() => setIsOpen(true)}
        className="btn btn--cyan-outline btn--xxxl"
        style={{ width: "100%" }}>
        {t("release.58")}
      </button>
      {imageURL ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}>
          <br />

          <img
            src={imageURL}
            alt="my signature"
            style={{
              display: "inline-block",

              margin: "0",
              border: "1px solid black",
              width: "80%",
              // marginLeft: "20px",
            }}
          />
          <br />
          <br />
        </div>
      ) : null}
    </>
  );
};

export default SignPad;
