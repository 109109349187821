import React, { useState } from "react";
import ReactPlayer from "react-player";
import "./index.css";

const VideoPlayer = ({ url }: { url: string }) => {
    // console.log(controlInput)

    // console.log(url)
    return (
        <div className="article__video">
            <div className="video-wrapper">
                <ReactPlayer url={url} playing={false} loop={false} controls={true} className="react-video-class" />
            </div>
        </div>
    );
};

export default VideoPlayer;
