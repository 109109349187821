import { Outlet } from "react-router-dom";
import { useRequiredCampaignState } from "../../helpers/campaignStates";
import Footer from "../../Components/Footer";

export default function Open() {
    useRequiredCampaignState("open");
    return (
        <div className="wrapper">
            <Outlet />
        </div>
    );
}
