import { useTranslation } from "react-i18next";
import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function ComingSoon() {
    useRequiredCampaignState("comingsoon");
    const { t } = useTranslation();
    return (
        <div className="wrapper">
            <div className="main">
                <section className="section section-full large-top-offset">
                    <div className="shell">
                        <div className="section__inner">
                            <img src="assets/images/temp/logo2.png" alt="" width="338" height="157" />
                            <h2>this Site is coming soon, please check again later.</h2>
                        </div>
                        {/* /.section__inner */}

                        <figure className="section__figure hidden-xs">
                            <img src="assets/images/svg/figures-left.svg" alt="" width="452" height="580" />
                        </figure>
                        {/* /.section__figure */}

                        <figure className="section__figure section__figure--alt hidden-xs">
                            <img src="assets/images/svg/figures-right.svg" alt="" width="507" height="608" />
                        </figure>
                        {/* /.section__figure */}

                        <figure className="section__figure visible-xs-block">
                            <img src="assets/images/svg/figures-mobile.svg" alt="" width="501" height="351" />
                        </figure>
                        {/* /.section__figure */}
                    </div>
                    {/* /.shell */}
                </section>
                {/* /.section */}
            </div>
            {/* /.main */}

            <footer className="footer">
                <div className="shell">
                    <ul>
                        <li>
                            <a href="https://us.sodexo.com/legal-privacy.html" target="_blank" rel="noreferrer">
                                Terms & Conditions
                            </a>
                        </li>

                        <li>
                            <a href="https://us.sodexo.com/legal-privacy/privacy-policy.html" target="_blank" rel="noreferrer">
                                Privacy Policy
                            </a>
                        </li>
                    </ul>

                    <p className="copyright">© 2023 Sodexo. All rights reserved</p>
                    {/* /.copyright */}
                </div>
                {/* /.shell */}
            </footer>
            {/* /.footer */}

            <div className="svg-sprite hidden">
                <img src="./../../build/assets/images/sprite.svg" alt="image1"></img>
            </div>
        </div>
    );
}
