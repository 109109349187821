import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams,useLocation } from "react-router-dom";
import { campaignClient } from "../../../../api";
import { disableBrowserHistory } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs'
import { Event } from "../Attendance";

// Confirm page

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Confirm = () => {
  disableBrowserHistory();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [finalistFirstName, setFinalistFirstName] = useState<string>();
  const [event,setEvent]=useState<Event|null>()

  const { session_key } = useParams();

  const verifyFinalist = async () => {
    try {
      const { firstName,eventDetail }: { firstName: string ,eventDetail:Event} = await campaignClient.call("checkSubmission", {
        sessionKey: session_key,
      });
      setFinalistFirstName(firstName);
      setEvent(eventDetail)
    } catch (error) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (session_key) {
      verifyFinalist();
    }
  }, []);

  if (finalistFirstName) {
    return (
      <div className="wrapper">
        <div className="main">
          <div className="hero hero--alt">
            <div className="shell">
              <div className="hero__image">
                <img src="assets/images/hero-image@2x.png" alt="" width={414} />
              </div>
            </div>
          </div>
          <section className="section-main section-main--confirmed">
            <div className="shell">
              <div className="section__content">
                <h2> {t("conf.1")}</h2>
                <div className="section__icon">
                  <img src="assets/images/svg/check-big-cyan.svg" alt="" />
                </div>
                <div className="section__entry">
                  <p>
                    {t("conf.2")}
                    <br /> {t("conf.3")} {event?dayjs(event.eventDate).format("MMM DD, YYYY"):""}
                  </p>
                  <p>
                    <strong>
                      {t("conf.4")}{" "}
                      {finalistFirstName.charAt(0).toUpperCase() + finalistFirstName.slice(1)}!
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Confirm;
