export const disableBrowserHistory = () => {
    window.history.pushState(null, null || "", window.location.href);
    window.onpopstate = function (event) {
        window.history.pushState(null, "", window.location.href);
    };
};

export const convertcaps = (data: string) => {
    return data
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

export type Participant = {
    sessionKey: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    address: string | null;
    address2: string | null;
    city: string | null;
    province: string | null;
    postal: string | null;
    country: string | null;
    preferredLanguage: string;

    creationTime: string;
    updateTime: string;
    metadata: {
      videoUrl: string;
        region: string;
        schoolName: string;
        districtName: string;
        grade: string;
        parent: {
            lastName: string;
            firstName: string;
        };
        recipe: {
            name: string;
            photoKey: string;
            ingredients: string[];
            preparationMethod: string[];
        };
        release:{
          child: {
            state: string
          }
        }
    };
};
