import React, { useEffect, useState } from "react";
import { disableBrowserHistory } from "./../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { campaignClient } from "../../../../api";
import Footer from "../../../../Components/Footer";

const PublicThankyou = () => {
    disableBrowserHistory();
    const { t } = useTranslation();
    const [announcedData, setAnnouncedData] = useState("");

    async function getLocalState() {
        const state = await campaignClient.getState();
        if (state.metadata?.announced) {
            setAnnouncedData(state.metadata?.announced);
        }
    }
    
    useEffect(() => {
        getLocalState();
    }, []);

    return (
        <>
            <div className="main" style={{ height: "100%", overflow: "hidden" }}>
                <section className="section  top-offset" style={{ overflow: "initial" }}>
                    <div className="shell">
                        <div className="section__inner">
                            <img src="assets/images/temp/logo2.png" alt="" width="338" height="157" />

                            <figure>
                                <img src="assets/images/svg/ico-check.svg" alt="" width="121" height="112" />
                            </figure>

                            <h3>{t("public.thanks")}</h3>

                            <p>
                                {t("public.announced")}
                                <span> {announcedData}</span>.
                            </p>
                        </div>
                        {/* /.section__inner */}
                        <figure className="section__figure hidden-xs">
                            <img src="assets/images/svg/figures-left.svg" alt="" width="452" height="580" />
                        </figure>
                        {/* /.section__figure */}

                        <figure className="section__figure section__figure--alt hidden-xs">
                            <img src="assets/images/svg/figures-right.svg" alt="" width="507" height="608" />
                        </figure>
                        {/* /.section__figure */}

                        <figure className="section__figure visible-xs-block">
                            <img src="assets/images/svg/figures-mobile.svg" alt="" width="501" height="351" />
                        </figure>
                        {/* /.section__figure */}
                    </div>
                    {/* /.shell */}
                </section>
                {/* /.section */}
            </div>
            {/* /.main */}
            <Footer />

            <div className="svg-sprite hidden">
                <img src="./../../build/assets/images/sprite.svg"></img>
            </div>
        </>
    );
};

export default PublicThankyou;
